export const issueMixin = {
  data: () => ({
    statusList: null,
    ISSUE_LINK_TYPE: {
      ABSTRACT_DATA_LINK: 'ABSTRACT_DATA_LINK',
      EXTERNAL_TABLE_ITEM_LINK: 'EXTERNAL_TABLE_ITEM_LINK',
      MAP_POINT_LINK: 'MAP_POINT_LINK',
      LAYER_POI_LINK: 'LAYER_POI_LINK'
    }
  }),
  mounted () {
    if (!this.statusList) {
      this.$axios.get('issue/get-all-statuses')
        .then(res => this.statusList = res.data)
    }
  },
  methods: {
    createEtItemIssueLink (et, item) {
      const primaryHeaderAlias = et.headers.find(h => h.isPrimaryTableKey).alias
      return {
        link_type: this.ISSUE_LINK_TYPE.EXTERNAL_TABLE_ITEM_LINK,
        etId: et.id,
        itemPrimaryKey: item[primaryHeaderAlias]
      }
    }
  }
}
