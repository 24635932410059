const OPEN_ISSUES = {
  title: 'issueList',
  icon: 'mdi-checkbox-marked-outline',
  color: 'white',
  background: 'var(--v-primary-base)',
  onlyAuthenticated: true,
  action: 'openIssues',
  border: 'none'
}

const SHARE = {
  title: 'button.share',
  icon: 'link',
  color: 'primary',
  background: 'white',
  onlyAuthenticated: false,
  action: 'share',
  border: '1px solid var(--v-primary-base)'
}

const EDIT = {
  title: 'button.edit',
  icon: 'edit',
  color: 'primary',
  background: 'white',
  onlyAuthenticated: true,
  action: 'edit',
  border: '1px solid var(--v-primary-base)'
}

const DIRECT_TO_MAP = {
  title: 'button.directToMap',
  icon: 'map',
  color: 'white',
  background: 'var(--v-primary-base)',
  primary: true,
  onlyAuthenticated: true,
  action: 'directToMap',
  border: 'none'
}

const REMOVE = {
  title: 'delete',
  icon: 'delete_outline',
  color: 'red',
  background: 'white',
  onlyAuthenticated: true,
  action: 'remove',
  border: '1px solid var(--v-red-base)'
}

const POI_INFO = {
  title: 'button.info',
  icon: 'info',
  color: 'white',
  background: 'var(--v-primary-base)',
  onlyAuthenticated: false,
  action: 'info',
  border: 'none'
}

const ATTACHED_DATA = {
  title: 'button.attachedData',
  icon: 'attach_file',
  color: 'primary',
  background: 'white',
  onlyAuthenticated: false,
  action: 'attachData',
  border: '1px solid var(--v-primary-base)'
}

const HISTORY = {
  title: 'button.history',
  icon: 'history',
  color: 'primary',
  background: 'white',
  onlyAuthenticated: true,
  action: 'history',
  border: '1px solid var(--v-primary-base)'
}

const CALC = {
  title: 'button.calculate',
  icon: 'functions',
  color: 'primary',
  background: 'white',
  onlyAuthenticated: false,
  action: 'calculate',
  border: '1px solid var(--v-primary-base)'
}

const PRINT_TEMPLATE = {
  title: 'button.printTemplates',
  icon: 'print',
  color: 'primary',
  background: 'white',
  onlyAuthenticated: true,
  action: 'printTemplate',
  border: '1px solid var(--v-primary-base)'
}

const GEOMETRY = {
  title: 'geometry',
  icon: 'mdi-vector-polygon',
  color: 'primary',
  background: 'white',
  onlyAuthenticated: true,
  action: 'openGeometry',
  border: '1px solid var(--v-primary-base)'
}

const SPACER = {
  spacer: true
}

export {
  OPEN_ISSUES,
  SHARE,
  EDIT,
  DIRECT_TO_MAP,
  REMOVE,
  SPACER,
  POI_INFO,
  ATTACHED_DATA,
  HISTORY,
  CALC,
  PRINT_TEMPLATE,
  GEOMETRY
}
