<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    :width="shortMode ? 700 : 900"
    persistent
  >
    <v-card :height="shortMode ? '70vh' : '90vh'" class="d-flex flex-column">
      <v-card-title>
        {{ getItemTitle() }}
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs
        v-model="tab"
        bg-color="primary"
        class="mt-2 flex-grow-0 "
        density="compact"
        fixed-tabs
      >
        <v-tab href="#fields">{{ $t('fields') }}</v-tab>
        <v-tab href="#attachments">{{ $t('button.attachedData') }}</v-tab>
        <v-tab
          v-if="isAuthenticated && et.relations.length > 0 && !shortMode"
          href="#related"
        >
          {{ $t('relatedTables') }}
        </v-tab>
        <v-tab v-if="!shortMode && isAuthenticated && primaryHeader" href="#issues">{{ $t('issues') }}</v-tab>
      </v-tabs>

      <v-card-text class="overflow-y-auto flex-grow-1">
        <v-tabs-items v-model="tab">
          <v-tab-item value="fields">
            <et-item-card
              :et="et"
              :initialItem="initialItem"
              :item="item"
              :shortMode="shortMode"
            />
          </v-tab-item>
          <v-tab-item value="attachments">
            <et-item-attachments
              ref="attachments"
              :et="et"
              :item="item"
            />
          </v-tab-item>
          <v-tab-item v-if="!shortMode" value="related">
            <related-info-card
              :et="et"
              :item="item"
            />
          </v-tab-item>
          <v-tab-item value="issues">
            <et-item-issues
              ref="etItemIssues"
              :et="et"
              :item="item"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions v-if="!hideControls">
        <v-tabs-items v-model="tab" class="full-width">
          <v-tab-item value="fields">
            <div class="d-flex">
              <v-btn v-if="isAuthenticated" color="black" outlined @click="duplicate">
                {{ $t('duplicate') }}
                <v-icon class="ml-1" small>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn v-if="isAuthenticated" class="ml-2" color="black" outlined @click="print">
                {{ $t('button.print') }}
                <v-icon class="ml-1" small>mdi-printer</v-icon>
              </v-btn>
              <v-btn
                v-if="isAuthenticated && setAbility('MODERATOR')"
                class="ml-2"
                color="red"
                outlined
                @click="$refs.confirmationDialog.open()"
              >
                {{ $t('button.remove') }}
                <v-icon class="ml-1" small>mdi-delete</v-icon>
              </v-btn>
              <v-spacer/>
              <v-btn v-if="isAuthenticated" class="ml-2" color="primary" outlined @click="update">
                {{ $t('button.save') }}
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item v-if="isAuthenticated" value="attachments">
            <div class="d-flex">
              <v-btn
                class="mr-2"
                color="black"
                outlined
                @click="$refs.attachments.openSearchDataDialog()"
              >
                {{ $t('linkDataFromSystem') }}
                <v-icon right>attach_file</v-icon>
              </v-btn>
              <v-spacer/>
              <v-btn
                color="primary"
                outlined
                @click="$refs.attachments.openNewFileDialog()"
              >
                {{ $t('addFile') }}
                <v-icon right>upload_file</v-icon>
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item v-if="isAuthenticated" value="issues">
            <div class="d-flex">
              <v-spacer/>
              <v-btn
                color="primary"
                outlined
                @click="() => $refs.etItemIssues.createNewIssue()"
              >
                {{ $t('button.create') }}
              </v-btn>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-actions>
    </v-card>
    <confirmation-dialog ref="confirmationDialog" :title="$t('deleteConfirmation')" @confirm="remove"/>
    <et-add-row-dialog ref="etAddRowDialog" @save="$emit('updated')"/>
  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/et-item/messages'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import { etServiceMixin } from '@/mixins/et-service-mixin'
import RelatedInfoCard from '@/components/et/utils/RelatedInfoCard.vue'
import EtAddRowDialog from '@/components/et/EtAddRowDialog.vue'
import EtItemAttachments from '@/components/et/EtItemAttachments.vue'
import sharedMessages from '@/componet-locale/attached-data/messages'

export default {
  name: 'EtItemDialog',
  components: {
    EtItemIssues: () => import('@/components/et/utils/EtItemIssues.vue'),
    EtItemAttachments,
    EtAddRowDialog,
    RelatedInfoCard,
    ConfirmationDialog,
    EtItemCard: () => import('@/components/et/EtItemCard.vue')
  },
  mixins: [etServiceMixin],
  props: {
    shortMode: {
      type: Boolean,
      default: false
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  },
  i18n: { messages, sharedMessages },
  data: () => ({
    dialog: false,
    item: null,
    et: null,
    tab: 'fields',
    selectedIds: null,
    initialItem: null
  }),
  methods: {
    open (et, item, selectedIds, tab = 'fields') {
      this.et = JSON.parse(JSON.stringify(et))
      this.initialItem = JSON.parse(JSON.stringify(item))
      this.item = JSON.parse(JSON.stringify(item))
      this.selectedIds = selectedIds
      this.tab = tab
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.tab = 'fields'
      this.et = null
      this.item = null
      this.initialItem = null
      this.selectedIds = null
    },
    update () {
      const initialItemId = this.primaryHeader
        ? JSON.parse(JSON.stringify(this.initialItem[this.primaryHeader.alias]))
        : null
      this.updateRow(this.et, this.item, this.selectedIds || [initialItemId.toString()])
    },
    remove () {
      this.deleteRow(this.et, this.selectedIds || [this.initialItem[this.primaryHeader.alias]])
    },
    getItemTitle () {
      if (this.et.rowNameConstructor) {
        return Object.keys(this.item).reduce((acc, key) => {
          return acc.replaceAll(`[${key}]`, this.item[key])
        }, this.et.rowNameConstructor)
      } else {
        if (this.primaryHeader) return `${this.et.name} [${this.item[this.primaryHeader.alias]}]`
        return this.et.name
      }
    },
    print () {
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      let rows = []
      this.et.headers.forEach(header => {
        rows.push(`<div><b>${header.fieldName}: </b>${this.item[header.alias]}</div>`)
      })
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
            <style>
                div {padding: 10px}
            </style>
        </head>
        <body>
          ${rows.join('')}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    duplicate () {
      const autoInsert = this.et.headers.map(h => ({
        header: h,
        value: this.item[h.alias]
      }))
      this.$refs.etAddRowDialog.open(this.et.id, autoInsert)
    }
  },
  computed: {
    primaryHeader () {
      const primaryHeaders = this.et.headers.filter(el => el.isPrimaryTableKey)
      if (primaryHeaders.length > 0) return primaryHeaders[0]
    }
  }
}
</script>
