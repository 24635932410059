<template>
  <div>
    <v-app-bar
      v-if="data"
      app
      color="primary-bar"
      dark
      fixed
      style="z-index: 200"
    >
      <img
        :src="data.icon || siteInfo.COMPANY_LOGO"
        class="mr-3"
        style="height: 48px; cursor: pointer"
        @click="$router.push('/')"
      />
      {{ data.name }}
      <v-spacer/>
      <v-btn v-if="!displayXS" icon @click="$refs.linkDialog.share()">
        <v-icon>share</v-icon>
      </v-btn>
      <v-btn v-if="data && data.description" icon @click="dialog=true">
        <v-icon>info</v-icon>
      </v-btn>
      <v-btn v-if="data.dataType === 'MAP'" icon @click="$refs.dataViewer.search()">
        <v-icon>search</v-icon>
      </v-btn>
    </v-app-bar>

    <div v-if="data" style="height: calc(100vh - 55px); width: 100%">
      <component
        :is="getComponent"
        ref="dataViewer"
        :data="data"
      />
    </div>

    <share-data-dialog ref="linkDialog"/>
    <v-dialog
      v-if="data && data.description"
      v-model="dialog"
      scrollable
      width="800"
    >
      <v-card>
        <v-card-title>
          <v-list-item>
            <v-list-item-content>
              {{ data.name }}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="dialog = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-card-text>
          {{ data.description }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MapConfig from '@/components/map/helpers/MapConfig.vue'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { EventBus } from '@/event-bus'
import ShareDataDialog from '@/components/utils/ShareDataDialog.vue'
import DMap from '@/components/map/DMap.vue'
import axios from 'axios'
import PublicMapViewer from '@/components/public-data/PublicMapViewer.vue'
import PublicDataException from '@/components/public-data/PublicDataException.vue'
import PublicDocViewer from "@/components/public-data/PublicDocViewer.vue";
import PublicEtViewer from '@/components/public-data/PublicEtViewer.vue'

export default {
  name: 'PublicDataViewer',
  components: { PublicMapViewer, PublicDocViewer, DMap, ShareDataDialog, MapConfig },
  mixins: [displayServiceMixin],
  data: () => ({
    dialog: false,
    siteInfo: null,
    data: null
  }),
  mounted () {
    this.init()
    EventBus.$on('showMapFeature', () => this.searchBar = false)
  },
  methods: {
    init () {
      axios
        .get('settings/get-general')
        .then(({ data }) => this.siteInfo = data)
      let id = this.$route.params.id
      this.$axios
        .get('public-data/get', { params: { id: id } })
        .then(({ data }) => this.data = data)
    }
  },
  computed: {
    getComponent () {
      switch (this.data.dataType) {
        case 'MAP':
          return PublicMapViewer
        case 'DOC':
          return PublicDocViewer
        case 'ET':
          return PublicEtViewer
        default:
          return PublicDataException
      }
    }
  }
}
</script>
