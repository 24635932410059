<template>
  <div class="d-flex" style="height: 100%; width: 100%;">
    <div style="height: 100%">
      <div
        v-if="panelExpanded"
        class="hidden-xs-only"
        style="width: 280px;  height: 100%; border-right: solid 1px #d6d2d2; background: var(--v-grey-lighten5);"
      >
        <v-card-actions class="py-0">
          <div class="body-1 ml-2 grey--text text--darken-2">
            {{ $t('info') }}
          </div>
          <v-spacer/>
          <v-btn icon @click="panelExpanded = !panelExpanded">
            <v-icon>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
          </v-btn>
        </v-card-actions>

        <v-card-actions v-if="issue">
          <v-chip
            :color="issue.status.isFinal ? 'green' : 'blue' "
            class="ml-2"
            label
            outlined
          >
            {{ issue.status.isFinal ? $t('closed') : $t('open') }}
          </v-chip>
          <v-spacer/>
          <v-menu :nudge-right="5" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-item dense @click="$refs.shareDataDialog.share()">
                  <v-list-item-avatar>
                    <v-icon>share</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    {{ $t('button.share') }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense @click="openRemoveConfirmationDialog">
                  <v-list-item-avatar>
                    <v-icon>delete</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    {{ $t('button.remove') }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-card-actions>

        <v-card-text>
          <v-text-field
            :label="$t('name')"
            :value="issue.name"
            class="simple-input"
            dense @change="changeName"/>

          <v-select
            :items="statusList"
            :label="$t('status')"
            :value="issue.status"
            class="simple-input mt-6"
            dense
            item-value="id"
            return-object
            @change="changeStatus"
          >
            <template v-slot:selection="{ item }">
                <span class="mr-2">
                  <v-avatar :color="item.color" size="12"/>
                </span>
              {{ item.name }}
            </template>

            <template v-slot:item="{ item }">
                <span class="mr-2">
                  <v-avatar :color="item.color" size="11"/>
                </span>
              {{ item.name }}
            </template>
          </v-select>

          <v-select
            :items="userList"
            :label="$t('executor')"
            :value="issue.performer"
            cache-items
            class="simple-input mt-6"
            dense
            hide-details
            item-value="id"
            return-object
            @change="assignee">
            <template v-slot:selection="{ item }">
              <user-avatar :user="item" class="mr-2" x-small/>
              <span class="hide-text-overflow">{{ item.firstName + ' ' + item.lastName }}</span>
            </template>
            <template v-slot:item="{ item }">
              <user-avatar :user="item" class="mr-2" x-small/>
              <span class="hide-text-overflow">{{ item.firstName + ' ' + item.lastName }}</span>
            </template>
          </v-select>
          <div style="height: 16px;">
            <a v-if="!issue.performer || issue.performer.id !== currentLoginUser.id"
               @click="assignee(currentLoginUser)">
              {{ $t('assignMe') }}
            </a>
          </div>

          <d-date-picker
            v-model="datePicker"
            :label="$t('completionDate')"
            :placeholder="$t('DD.MM.YYYY')"
            class="mt-9"
            dense
            hide-details
            simple-input
            @focusout="saveDueDate"
            @picked="saveDueDate"
          >
            <template v-slot:append>
                <span style="margin-top: 2px;">
                  <v-icon :color="overdue ? 'red' : ''">
                    {{ overdue ? 'event_busy' : 'date_range' }}
                  </v-icon>
                </span>
            </template>
          </d-date-picker>

          <category-picker
            v-model="issue.categoryList"
            :outlined="false"
            :project-id="+this.$route.params.projectId"
            class="mt-6"
            hide-details
            simple-input
            @change="updateDataCategoryList"
          />

          <keywords-picker
            ref="keywordsPicker"
            v-model:selected="issue.keywords"
            :outlined="false"
            :project-id="+this.$route.params.projectId"
            class="simple-input mt-6"
            hide-details
            @change="updateDataKeywords"
          />

        </v-card-text>
      </div>

      <div
        v-else="!panelExpanded"
        class="d-flex flex-column pointer hidden-xs-only"
        style="cursor: pointer; background: rgb(248, 248, 248); height: 100%; width: 36px; border-right: solid 1px #d6d2d2;"
        @click="panelExpanded=true"
      >
        <div>
          <v-btn class="mb-2" icon>
            <v-icon>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
          </v-btn>
        </div>
        <div class="flex-grow-1 ma-auto" style="writing-mode: tb-rl">{{ $t('info') }}</div>
      </div>
    </div>
    <div v-if="issue" class="flex-grow-1 overflow-hidden d-flex flex-column" style="height: 100%">
      <v-card-actions>
        <div class="body-1 ml-2 pr-8 inline-text">
          <v-breadcrumbs :items="breadcrumbs"/>
        </div>
        <v-spacer/>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon class="mx-2" @click="closePage" v-on="on">close</v-icon>
          </template>
          <span>{{ $t('button.close') }}</span>
        </v-tooltip>
      </v-card-actions>
      <div class="flex-grow-1 overflow-y-auto px-2">
        <div v-if="issue.poi && showMap">
          <div class="issue-view-content">
            <poi-viewer :poi="issue.poi"/>
          </div>
        </div>

        <div v-for="link in issue.links">
          <issue-link-widget
            :link="link"
          />
        </div>

        <div v-if="layerPoi && showMap" class="issue-view-container">
          <div class="issue-view-content">
            <layer-poi-map-widget :poi="layerPoi"/>
            <action-toolbar
              :actions="mapActions"
              class="pt-3"
              @directToMap="directToMap"
              @info="openPoiDetails"
            />
          </div>
        </div>

        <text-editor v-if="body" ref="textEditor" v-model="body" class="issue-view-content" @on-ready="isLoading=false"/>

        <div v-show="!isLoading" class="issue-view-container">

          <div class="issue-view-content">

            <issue-additional-fields v-if="issue.template" :issue="issue"/>

            <v-card-actions class="px-0" style="height: 52px">
                <span class="grey--text text--darken-1">
                  {{ $t('taskCreated') }} {{ formatDate(issue.creationDate) }}
                </span>
              <v-spacer/>
              <v-btn
                v-show="bodyIsChanged"
                outlined
                @click="cancelChange"
              >
                {{ $t('button.cancel') }}
              </v-btn>
              <v-btn
                v-show="bodyIsChanged"
                color="green"
                outlined
                @click="changeDescription"
              >
                {{ $t('button.save') }}
              </v-btn>
            </v-card-actions>

            <v-divider/>

            <v-card-actions class="pa-0 mt-3">
              <span class="grey--text text--darken-1">{{ $t('history') }}</span>
              <v-spacer/>
              <v-icon size="28">history</v-icon>
            </v-card-actions>

            <comments v-if="issue.chat && !issue.isLoading" ref="comments" :chat-id="issue.chat.id"/>

          </div>
        </div>
      </div>
    </div>
    <share-data-dialog ref="shareDataDialog"/>
    <layer-poi-dialog
      v-if="layerPoi "
      ref="layerPoiDialog"
      readonly-mode
    />
    <confirmation-dialog
      ref="removeConfirmationDialog"
      @confirm="remove"
    >
      <template #text>
        <div class="red--text">
          {{ $t('wannaRemoveIssue') }}
        </div>
      </template>
    </confirmation-dialog>
  </div>
</template>

<script>
import TextEditor from '@/components/utils/TextEditor'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import Comments from '@/components/chats/IssueComments'
import { EventBus } from '@/event-bus'
import UserAvatar from '@/components/utils/UserAvatar'
import moment from 'moment'
import { issueMixin } from '@/mixins/issue-mixin'
import messages from '@/componet-locale/issue-view/messages'
import PoiViewer from '@/components/map/PoiViewer'
import IssueAdditionalFields from '@/components/issue/IssueAdditionalFields'
import LayerPoiMapWidget from '@/components/layer-poi/LayerPoiMapWidget'
import ActionToolbar from '@/components/utils/ActionToolbar'
import { DIRECT_TO_MAP, POI_INFO, SPACER } from '@/components/map/helpers/map-actions'
import { getMapListByLayerPoi } from '@/components/layer-poi/layer-poi-service'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog'
import DDatePicker from '@/components/utils/DDatePicker.vue'
import CategoryPicker from '@/components/utils/CategoryPicker.vue'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import KeywordsPicker from '@/components/utils/KeywordsPicker.vue'
import IssueLinkWidget from '@/components/issue/widgets/IssueLinkWidget.vue'

export default {
  name: 'IssueView',
  components: {
    IssueLinkWidget,
    KeywordsPicker,
    ConfirmationDialog,
    CategoryPicker,
    DDatePicker,
    LayerPoiDialog,
    ActionToolbar,
    LayerPoiMapWidget,
    IssueAdditionalFields,
    PoiViewer,
    UserAvatar,
    Comments,
    ShareDataDialog,
    TextEditor
  },
  mixins: [issueMixin],
  props: {
    initIssueId: Number,
    dialogMode: Boolean
  },
  data: () => ({
    issue: null,
    body: null,
    layerPoi: null,
    copyOfBody: null,
    isLoading: true,
    showTextEditor: true,
    piker: false,
    showMap: true,
    userList: [],
    panelExpanded: true,
    mapVisible: true,
    mapActions: [POI_INFO, SPACER, DIRECT_TO_MAP]
  }),
  i18n: { messages },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$axios
        .get('project/get-users-by-project', {
          params: { id: this.selectedProject.id }
        })
        .then(response => this.userList = response.data)

      this.loadData()
    },
    loadData () {
      this.showMap = false
      this.$axios
        .get('issue/get', { params: { id: this.issueId } })
        .then(({ data }) => {
          this.issue = data
          if (this.issue.layerPoi) {
            this.layerPoi = this.issue.layerPoi
          } else {
            this.layerPoi = null
          }
          this.body = JSON.parse(this.issue.description)
          this.copyOfBody = { ...this.body }
          this.showMap = true
        })
    },
    directToMap () {
      if (this.issue.layerPoi) {
        getMapListByLayerPoi(
          this.issue.layerPoi,
          response => this.openInNewTab(
            response.data[0],
            null,
            { layerPoiId: this.issue.layerPoi.id }
          )
        )
      }
    },
    print () {
      const prtHtml = this.filteredSimpleValues.map(prop => {
        if (prop.isList) {
          let value = ''
          if (prop.value) {
            value = prop.list.find(it => it.code === prop.value).value
          }
          return `<div><b>${prop.username}: ${value}</b></div>`
        } else {
          return `<div><b>${prop.username}: </b>${prop.value}</div>`
        }
      }).join('')

      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
                <style>
                    div {padding: 10px}
                </style>
            </head>
            <body>
              ${prtHtml}
            </body>
          </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    openPoiDetails () {
      if (this.issue.layerPoi) {
        this.$refs.layerPoiDialog.open(this.issue.layerPoi)
      }
    },
    updateDataCategoryList () {
      let value = this.issue.categoryList.map(it => it.id).join()
      this.$axios
        .post('issue/update-category-list', null, {
          params: { id: this.issue.id, value: value }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('categoriesChanged'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'error')
        })
    },
    updateDataKeywords () {
      this.$axios
        .post('issue/update-keywords', this.issue.keywords, {
          params: { id: this.issue.id }
        })
        .then(res => {
          EventBus.$emit('showInfoMessage', this.$t('categoriesChanged'))
          if (res.data) this.issue.keywords = res.data
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'error')
        })
    },
    saveDueDate () {
      let date
      if (moment(this.issue.dueDate, 'DD.MM.YYYY', true).isValid()) {
        date = moment(this.issue.dueDate, 'DD.MM.YYYY').toDate().getTime()
      } else {
        date = null
      }
      this.$axios
        .post('issue/update-due-date', null, {
          params: { id: this.issue.id, date: date }
        })
        .then(() => EventBus.$emit('showInfoMessage', this.$t('dataChanged'))
        )
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    assignee (user) {
      const prevPerformer = this.issue.performer
      this.issue.performer = user
      let message = prevPerformer
        ? `<span class="grey--text text--darken-2">${this.$t('executorAssigned')}</span> ${prevPerformer.firstName} ${prevPerformer.lastName} ⭢ ${user.firstName} ${user.lastName}`
        : `<span class="grey--text text--darken-2">${this.$t('executorAssigned')}</span> ${user.firstName} ${user.lastName}`
      this.$axios
        .post('issue/assignee', null, {
          params: { id: this.issue.id, userId: user.id }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', `${user.firstName} ${user.lastName} - назначен`)
          this.$refs.comments.writeHistory(message)
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'error')
        })
    },
    cancelChange () {
      this.body = null
      this.isLoading = true
      setTimeout(() => {
        this.body = { ...this.copyOfBody }
      }, 100)
    },
    openRemoveConfirmationDialog () {
      this.$refs.removeConfirmationDialog.open()
    },
    remove () {
      this.$axios
        .post('issue/remove', null, {
          params: { id: this.issue.id }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('taskRemoved', { task: this.issue.name }))
          this.closePage()
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'savingError')
        })
    },
    changeStatus (status) {
      let previousStatus = this.issue.status

      let message = `<span class="grey--text text--darken-2">${this.$t('statusChanged')}: </span>
                        <span class="bold ${previousStatus.color}--text">${previousStatus.name}</span>
                        <span> \> </span>
                        <span class="bold ${status.color}--text">${status.name}</span>`
      this.issue.status = status
      this.$axios
        .post('issue/change-status', status, {
          params: { id: this.issue.id }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('statusChanged'))
          EventBus.$emit('issueStatusChanged', this.issue)
          this.$refs.comments.writeHistory(message)
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'error')
          this.issue.status = previousStatus.key
        })
    },
    changeDescription () {
      this.issue.description = JSON.stringify(this.body)
      if (this.issue.layerPoi) {
        this.issue.layerPoi.geometry = JSON.stringify(this.issue.layerPoi.geometry)
      }
      let message = this.$t('userChangedDescription')
      this.$axios
        .post('issue/update', this.issue)
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('descriptionChanged'))
          this.$refs.comments.writeHistory(message)
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'error')
        })
    },
    changeName (value) {
      let previousName = this.issue.name
      let message = `<span class="grey--text text--darken-2">${this.$t('userChangedName')}</span>: ${previousName} \> <b>${value}</b>`
      this.$axios
        .post('issue/update-name', null, {
          params: { id: this.issue.id, value: value }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('nameChanged'))
          this.issue.name = value
          this.$refs.comments.writeHistory(message)
        })
        .catch(() => {
          this.issue.name = previousName
          EventBus.$emit('showErrorMessage', 'error')
        })
    },
    closePage () {
      if (this.dialogMode) return this.$emit('close')
      let route = this.$route.matched[1]
      this.$router.push({ name: route.name, params: { projectId: this.selectedProject.id } })
    }
  },
  watch: {
    '$route.params.issueId' () {
      this.mapVisible = false
      setTimeout(() => {
        this.mapVisible = true
      }, 20)
      this.isLoading = true
      this.body = null
      this.loadData()
    }
  },
  computed: {
    issueId () {
      return this.$route.params.issueId || this.initIssueId
    },
    poi () {
      if (!this.issue.layer || !this.issue.sys) return null
      return {
        sys: this.issue.sys,
        layer: this.issue.layer
      }
    },
    breadcrumbs () {
      return [
        {
          text: this.issue.project.name,
          disabled: false,
          to: `/project/${this.issue.project.id}/home`
        },
        {
          text: 'Задачи',
          disabled: false,
          to: `/project/${this.issue.project.id}/ISSUE`
        },
        {
          text: this.issue.name,
          disabled: false,
          to: ''
        }
      ]
    },
    datePicker: {
      get: function () {
        return moment(this.issue.dueDate).format('DD.MM.YYYY')
      },
      set: function (str) {
        this.issue.dueDate = moment(str, 'DD.MM.YYYY').toDate()
      }
    },
    bodyIsChanged () {
      if (this.body && this.copyOfBody) {
        return JSON.stringify(this.body) !== JSON.stringify(this.copyOfBody)
      }
    },
    overdue () {
      if (this.issue.status && this.issue.status === 'DONE') return false
      if (this.issue && this.issue.dueDate) {
        return moment(this.issue.dueDate).isBefore(moment())
      }
    }
  }
}
</script>
