<template>
  <v-dialog v-if="setAbility('ADMIN') && dialog" v-model="dialog" persistent width="500">
    <v-card>
      <v-card-text>
        <div v-if="layer.type === 'XYZ'">
          <v-text-field
            v-model="layer.url"
            label="URL"
          />
          <v-select
            v-model="layer.projection"
            :items="['EPSG:3857', 'EPSG:4326', 'EPSG:3395']"
            label="CRS"
          />
        </div>

        <div v-if="layer.type === 'LAYER_POI'">
          <v-list>
            <v-list-item v-for="item in poiTemplateList">
              <v-list-item-action>
                <v-checkbox v-model="item.selected"/>
              </v-list-item-action>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>

        <div v-if="layer.type === 'WMTS' || layer.type === 'VECTOR_TILE' || layer.type === 'SANDBOX'">
          <v-text-field
            v-model="layer.url"
            label="URL"
          />
        </div>

        <div v-if="layer.type === 'WMTS'">
          <v-text-field
            v-model="layer.params"
            label="Layer params"
          />
        </div>

        <div v-if="layer.type === 'WMS'">
          <v-select
            v-model="layer.printTemplates"
            :items="printTemplates"
            :label="$t('button.printTemplates')"
            dense
            item-text="name"
            multiple
            outlined
            return-object
          />
        </div>

      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="close">{{ $t('button.back') }}</v-btn>
        <v-spacer/>
        <v-btn v-if="isLayerSupported(layer)" color="primary" outlined @click="save">
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/add-layer/messages'

export default {
  name: "EditLayerDialog",
  i18n: { messages },
  data: () => ({
    dialog: false,
    layer: null,
    poiTemplateList: [],
    printTemplates: []
  }),
  methods: {
    open (layer) {
      if (!layer) {
        return EventBus.$emit('showErrorMessage', this.$t('error'))
      } else if (this.isLayerSupported(layer)) {
        this.dialog = true
        this.layer = JSON.parse(JSON.stringify(layer))
        if (layer.type === 'LAYER_POI') this.loadPoiTemplateList()
        if (layer.type === 'WMS') this.loadPrintTemplates()
      } else {
        return EventBus.$emit('showErrorMessage', this.$t('error'))
      }
    },
    close () {
      this.dialog = false
    },
    isLayerSupported (layer) {
      switch (layer.type) {
        case 'XYZ':
        case 'WMTS':
        case 'WMS':
        case 'VECTOR_TILE':
        case 'LAYER_POI':
        case 'SANDBOX':
          return true
        default:
          return false
      }
    },
    loadPoiTemplateList () {
      this.$axios
        .get('layer-poi-template')
        .then(res => {
          this.poiTemplateList = res.data.map(template => ({
            ...template,
            selected: this.layer.poiTemplates.findIndex(el => el.id === template.id) >= 0
          }))
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    loadPrintTemplates () {
      this.$axios.get('print-template/get-all')
        .then(res => this.printTemplates = res.data)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    save () {
      const layer = JSON.parse(JSON.stringify(this.layer))
      if (this.layer.type === 'LAYER_POI') {
        layer.poiTemplates = this.poiTemplateList.filter(it => it.selected)
      }
      this.$axios
        .post('layer/save', {
          ...layer
        })
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('layerSaved')
          this.close()
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    }
  }
}
</script>
