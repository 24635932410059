<template>
  <v-container fill-height fluid>
    <v-layout align-center justify-center>
      <v-flex md4 sm8 xs12>
        <v-form
          lazy-validation
          ref="form"
          v-model="valid">
          <v-card :outlined="!displayXS" class="elevation-0">
            <v-card-title color="white">
              <v-toolbar-title>{{ $t('formTitle') }}</v-toolbar-title>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-text-field :rules="nameRules" @keyup.enter="submitData"
                            :label="$t('firstName')"
                            name="login"
                            prepend-icon="person"
                            required
                            type="text"
                            v-model="user.firstName">
              </v-text-field>
              <v-text-field :rules="surnameRules" @keyup.enter="submitData"
                            :label="$t('surname')"
                            name="login"
                            prepend-icon="person"
                            required
                            type="text"
                            v-model="user.lastName">
              </v-text-field>
              <v-text-field :rules="loginRules" @keyup.enter="submitData"
                            :label="$t('login')"
                            name="login"
                            prepend-icon="person"
                            required
                            type="text"
                            v-model="user.login">
              </v-text-field>
              <v-text-field :rules="emailRules" @keyup.enter="submitData"
                            :label="$t('email')"
                            name="login"
                            prepend-icon="alternate_email"
                            required
                            type="text"
                            v-model="user.email"/>
              <v-text-field @keyup.enter="submitData"
                            :label="$t('position')"
                            name="position"
                            prepend-icon="home_work"
                            type="text"
                            v-model="user.position"/>
              <v-text-field @keyup.enter="submitData"
                            :label="$t('company')"
                            name="company"
                            prepend-icon="home_work"
                            type="text"
                            v-model="user.company"/>
              <v-text-field :rules="passwordRules" @keyup.enter="submitData"
                            id="password"
                            :label="$t('password')"
                            name="password"
                            prepend-icon="lock"
                            required
                            type="password"
                            v-model="user.password">
              </v-text-field>
              <v-text-field v-model="user.phoneNumber"
                            :label="$t('phone')"
                            prepend-icon="local_phone"/>
              <v-switch
                :label="$t('smsNotification')"
                v-model="user.smsNotificationsAllowed"/>

              <v-switch
                :label="$t('emailNotification')"
                v-model="user.emailNotificationsAllowed"/>
            </v-card-text>
            <v-card-actions>
              <v-btn outlined to="/login">{{ $t('back') }}</v-btn>
              <span class="red--text">{{ errorInfo }}</span>
              <v-spacer/>
              <v-btn :disabled="!valid"
                     @click="submitData"
                     color="primary"
                     outlined>
                {{ $t('registration') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline lighten-2"
                      primary-title>
          {{ $t('confirmation') }}
        </v-card-title>
        <v-card-text>
          {{ $t('successMessage') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="toLoginPage" color="primary" outlined>{{ $t('next') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import messages from '../componet-locale/user-registration/messages'
import { EMAIL_PATTERN } from '@/utils/regexp'

export default {
  name: 'UserRegistration',
  data: () => ({
    valid: true,
    nameRules: [
      v => !!v || 'Имя должно быть заполнено'
    ],
    surnameRules: [
      v => !!v || 'Фамилия должна быть заполнена'
    ],
    loginRules: [
      v => !!v || 'Логин должен быть заполнен'
    ],
    emailRules: [
      v => !!v || 'E-mail должен быть заполнен',
      v => EMAIL_PATTERN.test(v) || 'E-mail заполнен не верно'
    ],
    passwordRules: [
      v => !!v || 'Пароль должен быть заполнен'
    ],
    user: {
      firstName: '',
      lastName: '',
      email: '',
      login: '',
      password: '',
      phoneNumber: '',
      smsNotificationsAllowed: false,
      emailNotificationsAllowed: false
    },
    dialog: false,
    errorInfo: '',
    siteInfo: {}
  }),
  i18n: { messages: messages },
  mixins: [displayServiceMixin],
  mounted(){
      this.$axios
        .get('settings/get-general')
        .then(response => this.siteInfo = response.data)
  },
  methods: {
    getRandomInt (min, max) {
      return Math.floor(Math.random() * (max - min)) + min
    },
    getRandomPhoto () {
      return 'https://image.flaticon.com/icons/png/512/64/64096.png'
    },
    submitData () {
      this.errorInfo = ''
      if (this.$refs.form.validate()) {
        this.user.avatar = this.getRandomPhoto()
        axios
          .post('/users/registration', this.user)
          .then(response => {
            if (response.status === 200) {
              this.dialog = true
            } else {
              this.errorInfo = 'error'
            }
          })
          .catch(reason => {
            this.errorInfo = reason.response.data.message
            console.error(reason)
          })
      }
    },
    toLoginPage () {
      this.$router.push('/login')
    }
  }
}
</script>
