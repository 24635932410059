<template>
  <div
    class="issue-view-content overflow-hidden my-2 et-info"
  >
    <ad-widget-container
      v-if="etSelectDto"
      :title="etSelectDto.tableName"
      dense
      @clickOnTitle="openExternalTable"
    >
      <v-list>
        <v-list-item
          @click="openEtItem"
        >
          <v-list-item-content>
            <v-list-item-title
              style="white-space: break-spaces"
              v-text="getItemName()"
            />
            <v-list-item-subtitle>
              <div style="width: 0">
                {{ getItemBody() }}
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </ad-widget-container>
    <et-item-dialog
      ref="etItemDialog"
      hide-controls
      short-mode
      @tableUpdated="init"
    />
  </div>
</template>

<script>
import EtViewer from '@/components/et/EtViewer.vue'
import AdWidgetContainer from '@/components/widget/AdWidgetContainer.vue'
import EtItemDialog from '@/components/et/EtItemDialog.vue'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/issue-widgets/messages'

export default {
  name: 'IssueExternalTableItemWidget',
  components: { EtItemDialog, AdWidgetContainer, EtViewer },
  i18n: { messages },
  props: {
    link: Object
  },
  data: () => ({
    etSelectDto: null
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$axios.get('et/get-item-by-primary-key', {
        params: {
          etId: this.link.etId,
          itemPrimaryKey: this.link.itemPrimaryKey
        }
      })
        .then(res => this.etSelectDto = res.data)
        .catch(() => EventBus.$emit('showErrorMessage'))
    },
    getItemName () {
      const rowNameConstructor = this.etSelectDto.rowNameConstructor
      const item = this.etSelectDto.body[0]
      if (!item) return this.$t('etItemNotFound')
      if (rowNameConstructor) {
        return Object.keys(item).reduce((acc, key) => {
          return acc.replaceAll(`[${key}]`, item[key])
        }, rowNameConstructor)
      } else {
        const primaryHeaders = this.etSelectDto.headers.filter(h => h.isPrimaryTableKey)
        if (primaryHeaders.length > 0) return `${this.etSelectDto.tableName} [${item[primaryHeaders[0].alias]}]`
        return this.etSelectDto.tableName
      }
    },
    getItemBody () {
      const fields = []
      const item = this.etSelectDto.body[0]
      if (!item) return ''
      for (let [key, value] of Object.entries(item)) {
        fields.push(`${this.etSelectDto.headers.filter(el => el.alias === key)[0].fieldName}: ${value}`)
      }
      return fields.join(' | ')
    },
    openExternalTable (e) {
      this.openInNewTab({ dataType: 'ET', id: this.etSelectDto.tableId }, e)
    },
    async openEtItem () {
      const et = await this.$axios.get('data/get', {
        params: {
          id: this.etSelectDto.tableId
        }
      })
        .then(res => res.data)
        .catch(() => EventBus.$emit('showErrorMessage'))
      this.$refs.etItemDialog.open(et, this.etSelectDto.body[0])
    }
  }
}
</script>
