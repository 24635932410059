<template>
  <feature-card @close="$emit('close')">
    <template #subtitle>
      {{ feature.get('address') || 'Без названия' }}
    </template>
    <template #fields>
      <v-text-field
        :value="feature.get('id') || ''"
        class="mb-4"
        dense
        hide-details
        label="Индентификатор объекта"
        outlined
        readonly
      />
      <v-text-field
        :value="feature.get('address') || ''"
        class="mb-4"
        dense
        hide-details
        label="Адрес или название"
        outlined
        readonly
      />
      <v-text-field
        :value="feature.get('t_n') || ''"
        class="mb-4"
        dense
        hide-details
        label="Температура наружного воздуха с датчика в ИТП"
        outlined
        readonly
      />
      <v-text-field
        :value="feature.get('t_p') || ''"
        class="mb-4"
        dense
        hide-details
        label="Температура в помещении (расчетная по параметрам)"
        outlined
        readonly
      />
      <v-text-field
        :value="feature.get('t_input') || ''"
        class="mb-4"
        dense
        hide-details
        label="Температура на вводе в ИТП из подающего трубопровода"
        outlined
        readonly
      />
      <v-text-field
        :value="feature.get('p1') || ''"
        class="mb-4"
        dense
        hide-details
        label="Давление на вводе в ИТП подающего трубопровода  (Р1)"
        outlined
        readonly
      />
      <v-text-field
        :value="feature.get('p2') || ''"
        class="mb-4"
        dense
        hide-details
        label="Давление в обратном трубопроводе  (Р2)"
        outlined
        readonly
      />
      <v-text-field
        :value="feature.get('date') || ''"
        class="mb-4"
        dense
        hide-details
        label="Дата получения данных"
        outlined
        readonly
      />
      <v-text-field
        :value="feature.get('status') || ''"
        class="mb-4"
        dense
        hide-details
        label="Статус связи"
        outlined
        readonly
      />
    </template>
  </feature-card>
</template>

<script>
import FeatureCard from '@/components/map/FeatureCard.vue'

export default {
  name: 'SandboxFeatureCard',
  components: { FeatureCard },
  props: {
    feature: Object
  }
}
</script>
