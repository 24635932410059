<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="900px"
  >
    <div v-if="dialog">
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            {{ $t('layerSelect') }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step step="2">
            {{ $t('layerParameter') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-select
              v-model="selectedType"
              :items="['LAYER_POI', 'ZWS', 'WMS', 'XYZ', 'WMTS', 'VECTOR_TILE', 'PKK', 'SANDBOX']"
              class="mt-2"
              label="Тип слоя"
              outlined
            />
            <div v-if="selectedType === 'WMS'">
              <v-list>
                <template v-for="item in wmsLayers">
                  <v-list-item
                    :key="item.Name"
                    @click="selectWmsLayer(item)">
                    <v-list-item-content>
                      <v-list-item-title v-html="item.Title"/>
                      <v-list-item-subtitle v-html="item.Abstract"/>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </div>
            <div v-if="selectedType === 'ZWS'">
              <div v-if="!this.layerList.length" class="text-center">
                <v-progress-circular
                  indeterminate
                  size="80"/>
              </div>

              <div v-if="this.layerList.length">
                <v-text-field v-model="searchField" :label="$t('search')" outline/>
                <v-list>
                  <template v-for="item in filteredLayerList">
                    <v-list-item
                      :key="item.Name"
                      @click="selectLayer(item)">
                      <v-list-item-content>
                        <v-list-item-title v-html="item.name"/>
                        <v-list-item-subtitle v-html="item.layerId"/>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </div>
            </div>
            <div v-if="selectedType === 'LAYER_POI'">
              <v-list>
                <v-list-item v-for="item in poiTemplateList">
                  <v-list-item-action>
                    <v-checkbox v-model="item.selected"/>
                  </v-list-item-action>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </div>

            <div v-if="selectedType === 'XYZ'">
              <v-text-field
                v-model="layer.url"
                label="URL"
              />
              <v-select
                v-model="layer.projection"
                :items="['EPSG:3857', 'EPSG:4326', 'EPSG:3395']"
                label="CRS"
              />
            </div>

            <div v-if="selectedType === 'WMTS' || selectedType === 'VECTOR_TILE' || selectedType === 'SANDBOX'">
              <v-text-field
                v-model="layer.url"
                label="URL"
              />
            </div>

            <div v-if="selectedType === 'WMTS'">
              <v-text-field
                v-model="layer.params"
                :label="$t('params')"
              />
            </div>

            <v-card-actions>
              <v-btn outlined text @click="dialog=false">
                {{ $t('button.cancel') }}
              </v-btn>
              <v-spacer/>
              <v-btn
                v-if="selectedType!=='ZWS'"
                color="primary"
                outlined
                text @click="e1=2"
              >
                {{ $t('button.next') }}
              </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="2">
            <abstract-data-edit-form :item="layer"/>
            <v-select
              v-if="setAbility('MODERATOR') && selectedType === 'WMS'"
              v-model="layer.printTemplates"
              :items="printTemplates"
              :label="$t('button.printTemplates')"
              dense
              item-text="name"
              multiple
              outlined
              return-object
            />
            <v-card-actions>
              <v-btn outlined text @click="e1=1">
                {{ $t('button.back') }}
              </v-btn>
              <v-spacer/>
              <v-btn color="primary"
                     outlined
                     @click="save">
                {{ $t('button.save') }}
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import AbstractDataEditForm from '@/components/utils/AbstractDataEditForm'
import messages from '@/componet-locale/add-layer/messages'
import { WMSCapabilities } from 'ol/format'
import { EventBus } from '@/event-bus'

export default {
  name: 'AddLayerDialog',
  components: { AbstractDataEditForm },
  data: () => ({
    dialog: false,
    searchField: '',
    selectedType: 'LAYER_POI',
    geoserverURL: '',
    wmsLayers: [],
    e1: 1,
    zuluServerUrl: process.env.VUE_APP_ZWS_API,
    layer: {},
    layerList: [],
    poiTemplateList: [],
    printTemplates: []
  }),
  i18n: { messages },
  methods: {
    findGeoserverLayers () {
      const parser = new WMSCapabilities()
      this.$axios
        .get(window.location.origin + '/geoserver/wms?service=wms&request=GetCapabilities')
        .then(response => {
          const result = parser.read(response.data)
          this.wmsLayers = result.Capability.Layer.Layer
        })
    },
    async findZwsLayers () {
      this.layerList = await ZwsCommandBuilder.getLayerList(this.zuluServerUrl)
    },
    showAddLayerDialog () {
      this.searchField = ''
      this.selectedType = 'LAYER_POI'
      this.layerList = []
      this.poiTemplateList = []
      this.e1 = 1
      this.layer = {
        type: 'LAYER_POI',
        url: '',
        name: '',
        params: '{"LAYER":"0", "TILEMATRIXSET":"EPSG:3857"}',
        projection: 'EPSG:3857'
      }
      this.$axios
        .get('layer-poi-template')
        .then(response => this.poiTemplateList = response.data)

      this.$axios.get('print-template/get-all')
        .then(res => this.printTemplates = res.data)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))

      this.dialog = true
    },
    save () {
      this.layer.project = this.selectedProject
      this.layer.type = this.selectedType
      if (this.selectedType === 'LAYER_POI') {
        this.layer.poiTemplates = this.poiTemplateList.filter(it => it.selected)
      }
      axios
        .post('layer/save', {
          ...this.layer
        })
        .then(() => {
          this.$emit('on-save')
          this.dialog = false
        })
    },
    selectLayer (layer) {
      this.layer.name = layer.name
      this.layer.layerId = layer.layerId
      this.e1 = 2
    },
    selectWmsLayer (layer) {
      this.layer.name = layer.Title
      this.layer.layerId = layer.Name
      this.e1 = 2
    },
    trimString (s) {
      let l = 0;
      let r = s.length - 1
      while (l < s.length && s[l] === ' ') l++
      while (r > l && s[r] === ' ') r -= 1
      return s.substring(l, r + 1)
    },
    compareObjects (o1, o2) {
      var k = ''
      for (k in o1) if (o1[k] !== o2[k]) return false
      for (k in o2) if (o1[k] !== o2[k]) return false
      return true
    },
    itemExists (haystack, needle) {
      for (var i = 0; i < haystack.length; i++) if (this.compareObjects(haystack[i], needle)) return true
      return false
    }
  },
  computed: {
    filteredLayerList () {
      let results = []
      let toSearch = this.trimString(this.searchField) // trim it
      for (var i = 0; i < this.layerList.length; i++) {
        for (var key in this.layerList[i]) {
          if (this.layerList[i][key].toLowerCase().indexOf(toSearch.toLowerCase()) !== -1) {
            if (!this.itemExists(results, this.layerList[i])) results.push(this.layerList[i])
          }
        }
      }
      return results
    }
  },
  watch: {
    selectedType (val) {
      switch (val) {
        case 'WMS':
          this.findGeoserverLayers()
          break
        case 'ZWS':
          this.findZwsLayers()
          break
      }
    }
  }
}
</script>
