<template>
  <div>
    <component
      :is="getComponent()"
      v-if="getComponent()"
      :link="link"
    />
  </div>
</template>

<script>
import { issueMixin } from '@/mixins/issue-mixin'
import IssueExternalTableItemWidget from '@/components/issue/widgets/IssueExternalTableItemWidget.vue'

export default {
  name: 'IssueLinkWidget',
  mixins: [issueMixin],
  components: { IssueExternalTableItemWidget },
  props: {
    link: Object
  },
  methods: {
    getComponent () {
      switch (this.link.link_type) {
        case this.ISSUE_LINK_TYPE.EXTERNAL_TABLE_ITEM_LINK:
          return IssueExternalTableItemWidget
      }
    }
  }
}
</script>
