<template>
  <v-dialog
    v-model="dialog"
    inset
    persistent
    scrollable
    width="750"
  >
    <v-card
      v-if="dialog"
      style="min-height: 80vh"
    >
      <v-card-title class="pb-0">
        <v-text-field
          v-model="issue.name"
          :placeholder="$t('name')"
          autofocus
          class="input-header"
        />
      </v-card-title>
      <v-card-text class="pt-2">
        <v-select
          v-if="templateList.length>0"
          v-model="issue.template"
          :items="templateList"
          class="input-header"
          item-text="name"
          placeholder="Тип задачи"
          return-object
        />

        <layer-poi-map-widget
          v-if="issue.layerPoi && mapWidget"
          :poi="issue.layerPoi"
        />

        <v-card-actions class="pa-0 mb-3">

          <d-date-picker
            v-model="issue.dueDate"
            :label="$t('completionDate')"
            dense
            hide-details
            outlined
            prepend-inner-icon="mdi-calendar"
          />

          <v-select
            v-model="issue.performer"
            :items="userList"
            :label="$t('executor')"
            cache-items
            class="ml-1"
            dense
            hide-details
            item-value="id"
            outlined
            prepend-inner-icon="person"
            return-object
          >
            <template v-slot:selection="{ item }">
              <user-avatar :user="item" x-small/>
              <span class="hide-text-overflow">
                {{ item.firstName + ' ' + item.lastName }}
              </span>
            </template>
            <template v-slot:item="{ item }">
              <user-avatar :user="item" class="mr-2" x-small/>
              <span class="hide-text-overflow">
                {{ item.firstName + ' ' + item.lastName }}
              </span>
            </template>
          </v-select>
        </v-card-actions>
        <category-picker
          v-model:selected="issue.categoryList"
          :project-id="+this.$route.params.projectId"
          class="mb-3"
          hide-details
        />

        <keywords-picker
          v-model:selected="issue.keywords"
          :project-id="+this.$route.params.projectId"
        />

        <issue-additional-fields
          v-if="issue.template"
          :issue="issue"
          :template="issue.template"
        />

        <div class="editor">
          <text-editor v-model="issue.description"/>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="dialog=false">{{ $t('button.cancel') }}</v-btn>
        <v-spacer/>
        <v-btn
          :loading="loading"
          color="primary"
          outlined
          @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import TextEditor from '@/components/utils/TextEditor'
import UserAvatar from '@/components/utils/UserAvatar'
import messages from '../../componet-locale/new-issue-dialog/messages'
import IssueAdditionalFields from '@/components/issue/IssueAdditionalFields'
import LayerPoiMapWidget from '@/components/layer-poi/LayerPoiMapWidget'
import { mask } from 'vue-the-mask'
import DDatePicker from '@/components/utils/DDatePicker.vue'
import moment from 'moment'
import CategoryPicker from '@/components/utils/CategoryPicker.vue'
import KeywordsPicker from '@/components/utils/KeywordsPicker.vue'
import _ from 'lodash'

export default {
  name: 'NewIssueDialog',
  components: {
    KeywordsPicker,
    CategoryPicker,
    DDatePicker,
    LayerPoiMapWidget,
    IssueAdditionalFields,
    UserAvatar,
    TextEditor
  },
  i18n: { messages },
  directives: { mask },
  props: {
    mapWidget: {
      type: Boolean,
      default: true
    },
    category: Object
  },
  data: () => ({
    dialog: false,
    piker: false,
    loading: false,
    issue: null,
    userList: [],
    templateList: [],
  }),
  methods: {
    init () {
      this.$axios
        .get('/project/get-users-by-project', {
          params: { id: this.selectedProject.id }
        })
        .then(response => this.userList = response.data)

      this.$axios
        .get('/issue-template/get-all')
        .then(response => {
          this.templateList = response.data
        })
    },
    open (poi = null, issueLink = null) { // todo перевести полностью на IssueLink
      this.loading = false
      this.issue = {
        name: '',
        poi: poi,
        additionalFields: [],
        description: { 'time': 1586976162351, 'blocks': [], 'version': '2.17.0' },
        categoryList: this.category ? [this.category] : [],
        links: issueLink ? [issueLink] : []
      }
      this.dialog = true
      this.init()
    },
    createLayerPoiIssue (layerPoi) {
      this.issue = {
        name: '',
        layerPoi: { id: layerPoi.id },
        additionalFields: [],
        description: { 'time': 1586976162351, 'blocks': [], 'version': '2.17.0' }
      }
      this.dialog = true
    },
    createMapIssue (coordinates, mapId) {
      this.dialog = true
      this.issue = {
        name: '',
        map: { id: mapId },
        coordinates: coordinates,
        additionalFields: [],
        description: { 'time': 1586976162351, 'blocks': [], 'version': '2.17.0' }
      }
    },
    save () {
      this.loading = true
      const issueToSave = _.cloneDeep(this.issue)
      issueToSave.description = JSON.stringify(issueToSave.description)
      issueToSave.project = this.selectedProject
      if (issueToSave.layerPoi) {
        issueToSave.layerPoi.geometry = JSON.stringify(issueToSave.layerPoi.geometry)
      }
      if (moment(issueToSave.dueDate, 'DD.MM.YYYY', true).isValid()) {
        issueToSave.dueDate = moment(issueToSave.dueDate, 'DD.MM.YYYY').toDate().getTime()
      }
      this.$axios
        .post('issue/save', issueToSave)
        .then(response => {
          this.$emit('on-save-issue')
          EventBus.$emit('showSuccessMessage', this.$t('taskCreated'))
          this.$emit('onSave', response.data)
          this.dialog = false
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>
.editor {
  padding: 10px 12px;
  border: 1px #9e9e9e solid;
  border-radius: 5px;
}

.editor:hover {
  border: 1px #484848 solid;
}
</style>
