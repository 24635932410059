<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('actionSettings') }}</span>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-form>
          <v-checkbox
            v-model="showMapButton"
            :label="$t('goToMap')"
            dense
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="showAttachmentsButton"
            :label="$t('openAttachments')"
            dense
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="showIssuesButton"
            :label="$t('showIssuesButton')"
            dense
            hide-details
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="black" outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/et-action-settings/messages'
import { EventBus } from '@/event-bus'

export default {
  name: 'EtActionSettingsDialog',
  i18n: { messages },
  data: () => ({
    dialog: false,
    et: null,
    showMapButton: true,
    showAttachmentsButton: true,
    showIssuesButton: false
  }),
  methods: {
    open (et) {
      this.et = et
      const actionSettingsFromCache = this.$store.getters.getEtActionSettings(et.id)
      const defaultActionSettings = et.actionSettings
      const settings = actionSettingsFromCache || defaultActionSettings
      this.showMapButton = settings.showMapButton
      this.showAttachmentsButton = settings.showAttachmentsButton
      this.showIssuesButton = settings.showIssuesButton
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.et = null
    },
    save () {
      if (this.setAbility('ADMIN')) {
        this.$axios.post('et/update-action-settings', {
          showMapButton: this.showMapButton,
          showAttachmentsButton: this.showAttachmentsButton,
          showIssuesButton: this.showIssuesButton
        }, { params: { etId: this.et.id } })
          .then(() => {
            EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
            this.$emit('update')
            this.close()
          })
      } else {
        this.$store.dispatch('updateEtActionSettings', {
          etId: this.et.id,
          settings: {
            showMapButton: this.showMapButton,
            showAttachmentsButton: this.showAttachmentsButton,
            showIssuesButton: this.showIssuesButton
          }
        })
        this.$emit('update')
      }
    }
  }
}
</script>
