<template>
  <vl-layer-vector
    v-if="features && features.length"
    :declutter="false"
    :z-index="zIndex"
    render-mode="image"
  >
    <vl-source-vector ref="sourceVector" :features="features">
      <vl-style-func :factory="getStyle" />
    </vl-source-vector>
  </vl-layer-vector>
</template>

<script>

import { EventBus } from '@/event-bus'
import { createStyle } from 'vuelayers/lib/ol-ext'

export default {
  name: 'SandboxLayer',
  props: {
    layer: {
      type: Object,
      required: true
    },
    zIndex: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
    features: []
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$axios.get(`${window.location.origin}/${this.layer.url}`)
        .then(res => res.data)
        .then(this.processRestData)
        .catch(() => EventBus.$emit('showErrorMessage', `${this.$t('error')}: ${this.layer.name}`))
    },
    processRestData (restData) {
      const features = []
      for (const unprocessedFeature of restData) {
        const [id, x, y, address, t_n, t_p, t_input, p1, p2, date, status] = unprocessedFeature
        const temps = [t_n, t_p, t_input]
        if(
          ['-300.0', '0.0'].some(el => temps.includes(el))
          || status !== 'Есть связь'
        ) continue
        features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [x, y]
          },
          properties: {
            id,
            isSandboxFeature: true,
            address,
            t_n,
            t_p,
            t_input,
            p1,
            p2,
            date,
            status,
            layer: this.layer
          }
        })
      }
      this.features = features
    },
    getStyle () {
      return feature => {
        const temperature = feature.get('t_p')

        let tempColor = '#00008B'
        if (temperature > 25) {
          tempColor = '#FF0000'
        } else if (temperature > 15) {
          tempColor = '#FF8C00'
        } else if (temperature > 5) {
          tempColor = '#FFD700'
        } else if (temperature > -5) {
          tempColor = '#1E90FF'
        } else if (temperature > -15) {
          tempColor = '#0000FF'
        }

        return createStyle({
          strokeColor: '#000000',
          strokeWidth: 1,
          imageRadius: 5,
          imageFillColor: tempColor,
          text: `${temperature}°C`,
          textOffsetX: 20,
          textOffsetY: -15,
          textFillColor: tempColor,
          textStrokeColor: '#FFFFFF',
          textStrokeWidth: 2,
          textFont: 'bold 12px Arial',
          textScale: 1.5
        })
      }
    }
  }
}
</script>
